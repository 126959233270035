
<template>
  <div class="signBankProtocolNobl">
    <!-- AGT003：《附件一-农村普惠金融服务合作协议》 -->
    <div class="content">
      <div class="title center bold">附件一-农村普惠金融服务合作协议</div>

      <div class="mt3 indent5">您（<span class="bold">指在本平台上传的符合平台要求的证明信所确定的被证明人</span>），为在加盟的 “村口壹站农村综合便民服务站”（简称服务站）开办“农村普惠金融服务”，特与{{companyName}}（简称村口科技）签订此协议。</div>

      <div class="mt3 bold ">第一章 合作内容</div>
      <div class="indent5">您须根据本协议约定在服务站开展服务站业务,您在开展服务站业务中须严格按照村口科技合作银行、村口科技制定的业务操作流程办理业务。</div>
      <div class="indent5">村口科技将按照相关考核办法规定的考核内容、计价方式及结算时间向您支付相应的服务费。</div>
      <div class="indent5 bold mt3">第二章 合作期限 </div>
      <div class="indent5">您同意并确认，本协议的有效期为<span class="underline">  {{agreementParams.para_date_period}}  </span>年，如双方无异议，自动续期1年，续期次数不限。协议期内，如您欲提前终止本协议，需至少提前【3】个月向村口科技提出书面申请。</div>
      <div class="indent5 bold mt3">第三章 权利与义务</div>
      <div class="indent5">1.村口科技的权利与义务</div>
      <div class="indent5">1.1权利</div>
      <div class="indent5">1.1.1村口科技及村口科技合作行有权自行制定服务站各项管理规定及考核办法，对您在服务站的服务及成果进行管理、检查和监督。</div>
      <div class="indent5">1.1.2“村口壹站”品牌属于村口科技所有。村口科技合作银行品牌属于村口科技合作银行所有。</div>
      <div class="indent5">1.1.3物资：在本协议签署之后，村口科技将协助村口科技合作银行向您提供物资及金融专用的自助机具耗材、金融业务宣传资料供您作为开展本协议所涉业务使用（设备、物资详见《村口壹站机具配置签收单》）。如果需要缴纳押金的，具体物资、设备和金额以《村口壹站机具配置签收单》为准。</div>
      <div class="indent5">1.1.4若合作方银行延期支付服务费，村口科技及村口科技合作方则顺延支付相应费用。</div>
      <div class="indent5">村口科技提供的设备、物资的所有权归村口科技所有；村口科技合作行提供的设备、物资的所有权归村口科技合作银行所有；管理、监督权归村口科技所有；使用权归您所有，<span class="grayColor">您应以合理的谨慎和注意确保您提供服务过程中不对自己和他人的人身、财产等权益造成损害；如造成损害的，相关风险和责任由您自行承担。</span></div>
      <div class="indent5">1.2义务</div>
      <div class="indent5">1.2.1村口科技将协助您取得村口科技合作银行授权开展农村金融服务的许可。</div>
      <div class="indent5">1.2.2为实施和开展服务站业务，村口科技或村口科技协调村口科技合作银行提供本协议本章中约定的设备、物资。</div>
      <div class="indent5">1.2.3村口科技负责或协调村口科技合作银行依照本协议约定提供相应的金融协助，包括但不限于：取得金融产品知识、业务技能、风险防范知识培训等。</div>
      <div class="indent5">2.您的权利与义务</div>
      <div class="indent5">2.1权利</div>
      <div class="indent5">2.1.1获得村口科技、村口科技合作银行的协助，包括但不限于：</div>
      <div class="indent5">2.1.1.1本协议本章1.1.3中村口科技合作银行所提供设备、物资的使用权。</div>
      <div class="indent5">2.1.1.2本协议本章1.2.3中村口科技、村口科技合作银行所提供的协助。</div>
      <div class="indent5">2.1.2根据本协议约定开展服务站业务。</div>
      <div class="indent5">2.1.3获取相关考核办法所约定的服务费。</div>
      <div class="indent5">2.1.4本协议到期后，在同等条件下，您拥有优先续签合作协议的权利。</div>
      <div class="indent5">2.2义务</div>
      <div class="indent5">2.2.1您须遵守并执行村口科技各项管理规定及考核办法。</div>
      <div class="indent5">2.2.2您须自筹金融业务营业所需备用金5万元。</div>
      <div class="indent5">2.2.3在本协议期间，未经村口科技的书面许可，您不得从事与本协议所涉业务存在竞争关系或类似的业务或为其他第三方提供与本协议相同或近似的服务或从事本协议约定业务以及根据后续签订的业务合作协议中未明确的业务。</div>

    </div>
    <div class="footer" v-show="signatureShow">
      <van-button type="info" color="#3F7C53" @click="signClick">签名</van-button>
    </div>
  </div>
</template>

<script>
import {COMPANY_NAME} from '@/utils/constantNum'
import { Button, Toast} from 'vant'
import {uwStatisticAction} from "../../utils/util";
export default {
  components:{
    [Button.name]: Button,
    [Toast.name]: Toast
  },
  data(){
    return{
      signatureShow: false,
      agreementParams: {},
      h5Name:'村口壹站',
      exampleStr:'举个栗子',
      companyName:COMPANY_NAME,
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.signatureShow = this.$route.query.sign == 'sign' ? true : false
      if(this.$route.query.agreementParams){
        this.agreementParams = JSON.parse(this.$route.query.agreementParams)
      }
    })
    uwStatisticAction('/agreement/signBankProtocol','附件一：农村普惠金融服务合作协议')
  },
  methods:{
    signClick() {
      this.$router.push({name:'signatureFour', query:{stationNo: this.$route.query.stationNo}})
    }
  }
}
</script>

<style lang="less" scoped>
.signBankProtocolNobl{
  background: #f5f5f5;
  font-size: 0.3rem;
  box-sizing: border-box;
  padding: 20px 0.3rem 100px 0.3rem ;
  -webkit-overflow-scrolling: touch;
  .content{
    .title{
      font-size: 0.4rem;
    }
    .bold{
      font-weight: bold
    }
    .center{
      text-align: center;
    }
    .indent5{
      text-indent: 0.6rem;
    }
    .mt3{
      margin-top: 0.3rem;
    }
    .underline{
      text-decoration: underline;
    }
    .grayColor{
      background-color: #cccccc;
    }
  }
  .footer{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    .van-button{
      width: 100%;
    }
  }
}
</style>
